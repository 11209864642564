/** @jsx jsx */
import "../../fonts/stylesheet.css";

import { graphql } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import { memo } from "react";
import { jsx, Styled, ThemeProvider } from "theme-ui";

import { PageQuery } from "../../../__generated__/queries";
import Container from "../../components/Container";
import Figure from "../../components/Figure";
import Footer from "../../components/Footer";
import Heading from "../../components/Heading";
import MainMenu from "../../components/MainMenu";
import Markdown from "../../components/Markdown";
import Section from "../../components/Section";
import SEO from "../../components/SEO";
import themeAquatic from "../../theme-aquatic";

const Page: React.FC<{ data: PageQuery }> = ({ data }) => {
  const pageData = data.markdownRemark?.frontmatter;
  const banner = pageData?.banner?.picture?.childImageSharp
    ?.fluid as FluidObject;

  return (
    <ThemeProvider theme={themeAquatic}>
      <SEO
        description={pageData?.seo?.description}
        image={pageData?.seo?.picture?.childImageSharp?.fluid?.src}
        title={pageData?.seo?.title || pageData?.page_name}
        url={pageData?.url}
      />
      <Styled.root sx={{ backgroundColor: "muted" }}>
        {banner && (
          <Figure
            customsx={{ display: "block" }}
            shadow={false}
            credits={pageData?.banner?.credits}
          >
            <Img
              alt={pageData?.banner?.alternative_text || ""}
              fluid={banner}
              style={{ height: "100%" }}
            />
          </Figure>
        )}
        <div
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
          }}
        >
          <Container>
            <MainMenu />
          </Container>
        </div>
        <Section customsx={{ backgroundColor: "transparent" }}>
          <Heading>{pageData?.page_name}</Heading>
          <Markdown>{data.markdownRemark?.rawMarkdownBody}</Markdown>
        </Section>
        <Footer isTerrestrial={false} />
      </Styled.root>
    </ThemeProvider>
  );
};

export const query = graphql`
  query PageQuery($fileAbsolutePath: String) {
    markdownRemark(fileAbsolutePath: { eq: $fileAbsolutePath }) {
      frontmatter {
        page_name
        url
        banner {
          alternative_text
          credits
          picture {
            childImageSharp {
              fluid(quality: 70, maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        seo {
          title
          description
          picture {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                src
              }
            }
          }
        }
      }
      rawMarkdownBody
    }
  }
`;

export default memo(Page);
